import React, { useState, useEffect, useRef } from 'react'
import { Link, Route, withRouter } from 'react-router-dom'

import styles from './index.module.scss'

function Index(props) {
  return (
    <div className={styles['main-wrapper']}>
      <div className={styles['leftbar']}></div>
      <div className={styles['copyright']}>
        Copyright &copy; 2021 BEYOND Bollywood.org. All Rights Reserved.
      </div>
    </div>
  )
}

export default Index
