import React, { useState, useEffect, useRef } from 'react'
import { Link, Route, withRouter } from 'react-router-dom'

import styles from './index.module.scss'

function Index(props) {
  return (
    <div className={styles['main-wrapper']}>
      <Link className={`btn ${styles['btn-back']}`} to={'/landing'}>
        <img src={require('../../assets/innerpage/btn-back.png').default} />
      </Link>
      <img src={require('../../assets/credit/credit.png').default} />
    </div>
  )
}

export default Index
