import React, { useState, useEffect, useRef, Fragment } from 'react'
import { Link, Route, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import styles from './index.module.scss'

import VR from './vr'
import Top from './top'
import Overlay from './overlay'

function Index(props) {
  return (
    <div className={styles['main-wrapper']}>
      <Top />
      <VR />
      {props.state.popup.show ? <Overlay /> : <Fragment />}
    </div>
  )
}

export default withRouter(
  connect((state) => {
    return {
      state: {
        popup: state.popup,
      },
    }
  })(Index)
)
