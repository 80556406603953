import React, { useState, useEffect, useRef } from 'react'
import { Link, Route, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import styles from './intro.module.scss'

function Index(props) {
  return (
    <div className={styles['main-wrapper']}>
      <style>
        {`
        .${styles['main-wrapper']}{
          background-image: url(${
            require(`../../assets/innerpage/${props.state.innerpage.data.intro.backgroundImage}`)
              .default
          })
        }
      `}
      </style>
      <div className={styles['content-wrapper']}>
        <div className={styles['top-overlay-color']}></div>
        <div className={styles['bottom-overlay-color']}></div>
        <div className={styles['content-inner-wrapper']}>
          <div className={styles['title-wrapper']}>
            <div className={styles['tc']}>
              {props.state.innerpage.data.intro.title_tc}
            </div>
            <div className={styles['en']}>
              {props.state.innerpage.data.intro.title_en}
            </div>
          </div>
          <div className={styles['desc-wrapper']}>
            <p>{props.state.innerpage.data.intro.desc_tc}</p>
            <p>{props.state.innerpage.data.intro.desc_en}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default withRouter(
  connect((state) => {
    return {
      state: {
        innerpage: state.innerpage,
      },
    }
  })(Index)
)
