import React, { useState, useEffect, useRef } from 'react'
import { Link, Route, withRouter } from 'react-router-dom'
import * as THREE from 'three'
import * as PANOLENS from 'panolens'
import { connect } from 'react-redux'

import styles from './vr.module.scss'
import pinsConfig from './pins_config.json'

import * as PopupAction from '../../redux/actions/popupAction'
import * as InnerPageAction from '../../redux/actions/innerpageAction'

function Index(props) {
  const [progress, setProgress] = useState(0)
  PANOLENS.Viewer.prototype.getPosition = function () {
    var intersects, point, panoramaWorldPosition, outputPosition
    intersects = this.raycaster.intersectObject(this.panorama, true)

    if (intersects.length > 0) {
      point = intersects[0].point
      panoramaWorldPosition = this.panorama.getWorldPosition()

      // Panorama is scaled -1 on X axis
      outputPosition = new THREE.Vector3(
        -(point.x - panoramaWorldPosition.x).toFixed(2),
        (point.y - panoramaWorldPosition.y).toFixed(2),
        (point.z - panoramaWorldPosition.z).toFixed(2)
      )
    }

    return outputPosition
  }

  const onProgress = (event) => {
    setProgress((event.progress.loaded / event.progress.total) * 100)
  }

  const onEnter = (event) => {
    setProgress(0)
  }

  useEffect(() => {
    let panorama, viewer
    let infospots = []

    panorama = new PANOLENS.ImagePanorama(
      require('../../assets/vr/background.jpg').default
    )

    // set start view position
    panorama.addEventListener('enter-fade-start', () => {
      if (window.innerWidth > 641) {
        viewer.tweenControlCenter(new THREE.Vector3(3781, -2362, 2250), 0)
        viewer.setCameraFov(85)
      } else if (window.innerWidth <= 640) {
        viewer.tweenControlCenter(new THREE.Vector3(4211, -2539, -886), 0)
      }
    })

    panorama.addEventListener('progress', onProgress)
    panorama.addEventListener('enter', onEnter)

    // debug use
    // panorama.addEventListener('click', () => {
    //   console.log(viewer.getPosition())
    //   // console.log(viewer.getCamera())
    // })

    for (let x in pinsConfig.pins) {
      let pinConfig = pinsConfig.pins[x]
      let tempInfospot

      tempInfospot = new PANOLENS.Infospot(
        1000,
        require(`../../assets/vr/pin/${pinConfig.config.image_name}`).default
      )

      tempInfospot.position.set(
        pinConfig.config.position.x,
        pinConfig.config.position.y,
        pinConfig.config.position.z
      )

      switch (pinConfig.config.infopage) {
        case 'popup':
          tempInfospot.addEventListener('click', function () {
            props.dispatch(
              PopupAction.updateValue({
                name: 'data',
                value: pinConfig.popup,
              })
            )
            props.dispatch(
              PopupAction.updateValue({
                name: 'show',
                value: true,
              })
            )
          })
          break
        case 'innerpage':
          tempInfospot.addEventListener('click', function () {
            props.history.push(`/landing/innerpage/${pinConfig.config.name}`)
          })
          break
      }

      infospots.push(tempInfospot)
    }

    panorama.add(...infospots)

    viewer = new PANOLENS.Viewer({
      // output: 'overlay',
      container: document.querySelector('#panorama-viewer'),
      autoHideInfospot: false,
      controlBar: false,
      autoRotate: true,
      autoRotateSpeed: 2.0,
      autoRotateActivationDuration: 100000
    })
    viewer.add(panorama)
  }, [])

  return (
    <div className={styles['main-wrapper']}>
      <div className={styles['panorama-viewer']} id="panorama-viewer"></div>
      <div
        className={`${styles['progress-bar']} ${
          progress == 100 && styles['finish']
        }`}
        style={{ width: `${progress}%` }}
      ></div>
    </div>
  )
}

export default withRouter(
  connect((state) => {
    return {
      state: {},
    }
  })(Index)
)
