import React, { useState, useEffect, useRef } from 'react'
import { Link, Route, withRouter } from 'react-router-dom'

import styles from './top.module.scss'

function Index(props) {
  return (
    <div className={styles['main-wrapper']}>
      <div className={styles['logo-wrapper']}></div>
      <div className={styles['icon-wrapper']}>
        <div className={styles['icon-360']}></div>
        <div className={styles['instruction-text']}>
          拉動畫面發掘大埔與印蹈的美！
        </div>
      </div>
    </div>
  )
}

export default Index
