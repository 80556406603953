import React, { useState, useEffect, useRef } from 'react'
import { Link, Route, withRouter } from 'react-router-dom'

import styles from './index.module.scss'

function Index(props) {
  const [menuOpen, setMenuOpen] = useState(false)
  const [menuDom, setMenuDom] = useState(false)
  const [menuOpacity, setMenuOpacity] = useState(false)

  const handleMenuButton = () => {
    if (!menuOpen) {
      setMenuOpen(true)
      setTimeout(() => {
        setMenuDom(true)
      }, 1000)
      setTimeout(() => {
        setMenuOpacity(true)
      }, 1300)
    } else {
      setMenuOpacity(false)
      setTimeout(() => {
        setMenuDom(false)
      }, 500)
      setTimeout(() => {
        setMenuOpen(false)
      }, 501)
    }
  }

  const changeRoute = (url) => {
    props.history.push(url)
    handleMenuButton()
  }

  return (
    <div
      className={`${styles['main-wrapper']} ${
        menuOpen ? styles['menu-open'] : styles['menu-close']
      }`}
    >
      <div
        className={`btn ${styles['btn-menu-wrapper']}`}
        onClick={() => handleMenuButton()}
      ></div>
      <div className={styles['main-title-wrapper']}></div>
      {menuDom && (
        <div
          className={`${styles['menu-wrapper']} ${
            menuOpacity ? styles['show'] : ''
          }`}
        >
          <div className={styles['menu-buttons-wrapper']}>
            <div
              className={`btn ${styles['menu-button']}`}
              onClick={() => changeRoute('/landing')}
            >
              大埔全景 Tai Po
            </div>
            <div
              className={`btn ${styles['menu-button']}`}
              onClick={() => changeRoute('/landing/innerpage/man-mou-temple')}
            >
              文武二帝廟 Man Mo Temple
            </div>
            <div
              className={`btn ${styles['menu-button']}`}
              onClick={() =>
                changeRoute('/landing/innerpage/old-district-office')
              }
            >
              舊北區理民府 Old District Office (North)
            </div>
            <div
              className={`btn ${styles['menu-button']}`}
              onClick={() => changeRoute('/landing/innerpage/kwong-fuk-bridge')}
            >
              廣福橋 Kwong Fuk Bridge
            </div>
            <div
              className={`btn ${styles['menu-button']}`}
              onClick={() =>
                changeRoute('/landing/innerpage/shuen-wan-typhoon-shelter')
              }
            >
              船灣避風塘 Shuen Wan Typhoon Shelter
            </div>
            <div className={`btn ${styles['menu-button']}`}>
              BEYOND Bollywood (Coming Soon)
            </div>
            <div
              className={`btn ${styles['menu-button']}`}
              onClick={() =>
                changeRoute('/landing/credit')
              }
            >
              創作團隊及歌曲版權 Creditive Team List & Song Credit
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default withRouter(Index)
