import React, { useState, useEffect, useRef, Fragment } from 'react'
import { Link, Route, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import ReactHtmlParser from 'react-html-parser'

import * as PopupActions from '../../redux/actions/popupAction'

import styles from './overlay.module.scss'

function Index(props) {
  const [imageIndex, setImageIndex] = useState(0)
  const handleBtnBack = () => {
    props.dispatch(
      PopupActions.updateValue({
        name: 'show',
        value: false,
      })
    )
  }

  let imageLoop
  const changeImage = () => {
    let idx = 0
    imageLoop = setInterval(() => {
      idx + 1 >= props.state.popup.data.images.length ? (idx = 0) : idx++
      setImageIndex(idx)
    }, 3000)
  }

  useEffect(() => {
    if (props.state.popup.data.images.length > 1) {
      changeImage()
    }
    return () => {
      clearInterval(imageLoop)
    }
  }, [])

  return (
    <div className={styles['main-wrapper']}>
      <div className={styles['inner-wrapper']}>
        <div className={styles['title-wrapper']}>
          <div className={styles['tc']}>
            {ReactHtmlParser(props.state.popup.data.title_tc)}
          </div>
          <div className={styles['en']}>
            {ReactHtmlParser(props.state.popup.data.title_en)}
          </div>
        </div>
        <div className={styles['content-wrapper']}>
          <div className={styles['content-inner-wrapper']}>
            <div className={styles['image-wrapper']}>
              <img
                src={
                  require(`../../assets/vr/popup/${props.state.popup.data.images[imageIndex]}`)
                    .default
                }
              />
            </div>
            <div className={styles['description-wrapper']}>
              <p>{props.state.popup.data.desc_tc}</p>
              <p>{props.state.popup.data.desc_en}</p>
            </div>
          </div>
        </div>
        <div
          className={`btn ${styles['btn-back-wrapper']}`}
          onClick={() => handleBtnBack()}
        >
          <img src={require('../../assets/vr/popup/btn-back.png').default} />
        </div>
      </div>
    </div>
  )
}

export default withRouter(
  connect((state) => {
    return {
      state: {
        popup: state.popup,
      },
    }
  })(Index)
)
