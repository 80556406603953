import React, { useEffect, useState } from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import { connect } from 'react-redux'

import RouteMain from './Route-Main'

import store from '../redux/index'

function App(props) {
  return (
    <Provider store={store}>
      <Router basename={process.env.REACT_APP_Router_BaseName}>
        <RouteMain />
      </Router>
    </Provider>
  )
}

export default App
