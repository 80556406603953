import React, { Component, Fragment, useState, useEffect } from 'react'
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { AnimatedSwitch } from 'react-router-transition'

import styles from './routemain.module.scss'

import Index from './Index/index'
import Header from './Header/index'
import Footer from './Footer/index'
import VR from './VR/index'
import InnerPage from './InnerPage/index'
import Credit from './Credit/index'

function RouteMain(props) {
  return (
    <Fragment>
      <AnimatedSwitch
        atEnter={{ opacity: 0 }}
        atLeave={{ opacity: 0 }}
        atActive={{ opacity: 1 }}
        className={styles['switch-wrapper']}
      >
        <Route exact path={`/`} component={Index} />
        <Route
          path="/landing"
          render={({ match: { url } }) => (
            <Fragment>
              <Header />
              <Switch>
                <Route path={`${url}/`} component={VR} exact />
                <Route
                  path={`${url}/innerpage/:name(man-mou-temple|kwong-fuk-bridge|old-district-office|shuen-wan-typhoon-shelter)`}
                  component={InnerPage}
                  exact
                />
                <Route path={`${url}/credit`} component={Credit} exact />
                <Redirect exact from="*" to="/" />
              </Switch>
              <Footer />
            </Fragment>
          )}
        />
        <Redirect exact from="*" to="/" />
      </AnimatedSwitch>
    </Fragment>
  )
}

export default withRouter(
  connect((state) => {
    return {
      state: {},
    }
  })(RouteMain)
)
