import React, { useState, useEffect, useRef, Fragment } from 'react'
import { Link, Route, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import pin_config from '../VR/pins_config.json'

import * as InnerPageActions from '../../redux/actions/innerpageAction'

import styles from './index.module.scss'

import Intro from './intro'
import DanceVideo from './dance-video'
import Learning from './learning'

function Index(props) {
  useEffect(() => {

    props.dispatch(
      InnerPageActions.updateValue({
        name: 'data',
        value: pin_config.pins.find(
          (config) => config.config.name == props.match.params.name
        ).innerpage,
      })
    )
  }, [props.match.params.name])

  return (
    <div className={styles['main-wrapper']}>
      <Link className={`btn ${styles['btn-back']}`} to={'/landing'}>
        <img src={require('../../assets/innerpage/btn-back.png').default} />
      </Link>
      {props.state.innerpage.data && (
        <Fragment>
          <Intro />
          <DanceVideo />
          {props.state.innerpage.data.learning && <Learning />}
        </Fragment>
      )}
    </div>
  )
}

export default withRouter(
  connect((state) => {
    return {
      state: {
        innerpage: state.innerpage,
      },
    }
  })(Index)
)
