import React, { useState, useEffect, useRef } from 'react'
import { Link, Route, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import ReactPlayer from 'react-player'
import htmlParser from 'react-html-parser'

import styles from './dance-video.module.scss'

// import VRVideoPlayer from './vr-videoplayer'

function Index(props) {
  let player
  const videoRef = useRef(null)
  const [videoPlaying, setVideoPlaying] = useState(false)
  const [hideContentWrapper, setHideContentWrapper] = useState(false)
  const [mouseMoving, setMouseMoving] = useState(false)

  const handleBtnPlay = () => {
    setVideoPlaying(true)
    setHideContentWrapper(true)
  }

  const handleBtnPause = () => {
    setVideoPlaying(false)
    setHideContentWrapper(false)
  }

  const videoPlayerPauseEventListener = () => {
    setVideoPlaying(false)
  }

  const videoPlayerEndedEventListener = () => {
    setVideoPlaying(false)
    setHideContentWrapper(false)
  }

  const getVideoPlayer = (type) => {
    switch (type) {
      case 'vr':
        break
      default:
        break
    }

    return (
      <ReactPlayer
        url={props.state.innerpage.data.video.videoName}
        width="100%"
        height="100%"
        playing={videoPlaying}
        controls={true}
        config={{
          youtube: {
            playerVars: { showinfo: 0 },
          },
        }}
        // onPause={videoPlayerPauseEventListener}
        onEnded={videoPlayerEndedEventListener}
      />
    )
  }

  let mouseMoveTimeout
  const onMouseMoveListener = (e) => {
    e.preventDefault()

    if (!mouseMoving) {
      setMouseMoving(true)
    } else {
      clearTimeout(mouseMoveTimeout)
      mouseMoveTimeout = setTimeout(() => {
        setMouseMoving(false)
        setHideContentWrapper(true)
      }, 3000)
    }
  }

  useEffect(() => {
    if (videoPlaying && mouseMoving) {
      setHideContentWrapper(false)
    }
  }, [mouseMoving])

  return (
    <div className={styles['main-wrapper']}>
      <div
        className={`${styles['background-video-wrapper']} ${
          styles[props.state.innerpage.data.video.backgroundVideoWrapperClass]
        } ${
          videoPlaying &&
          // props.state.innerpage.data.video.videoType == 'vr' &&
          styles['bring-front']
        }`}
      >
        {getVideoPlayer(props.state.innerpage.data.video.videoType)}
        <div
          className={`${styles['preview-image-wrapper']} ${
            videoPlaying ? styles['hide'] : ''
          }`}
          style={{
            backgroundImage: `url(${
              require(`../../assets/innerpage/${props.state.innerpage.data.video.previewImage}`)
                .default
            })`,
          }}
        ></div>
      </div>
      <div
        className={`${styles['content-wrapper']} ${
          videoPlaying && styles['video-playing']
        }`}
        onMouseMove={(e) => onMouseMoveListener(e)}
      >
        <div className={`${styles['top-overlay-color']}`}></div>
        <div className={`${styles['bottom-overlay-color']}`}>
          <div className={styles['desc-wrapper']}>
            <div className={styles['desc-inner-wrapper']}>
              <div
                className={`${styles['text-wrapper']} ${styles['left-wrapper']}`}
              >
                <div className={styles['title']}>
                  {props.state.innerpage.data.video.desc.title_tc}{' '}
                  {props.state.innerpage.data.video.desc.title_en}
                </div>
                <p>
                  {htmlParser(props.state.innerpage.data.video.desc.desc_tc)}
                </p>
              </div>
              <div
                className={`${styles['text-wrapper']} ${styles['right-wrapper']}`}
              >
                <p>
                  {htmlParser(props.state.innerpage.data.video.desc.desc_en)}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`${styles['content-inner-wrapper']} ${
            videoPlaying && hideContentWrapper ? styles['hide'] : ''
          }`}
        >
          <div
            className={`${styles['title-wrapper']} ${
              videoPlaying && styles['bring-front']
            }`}
          >
            <div className={styles['tc']}>
              {props.state.innerpage.data.video.title_tc}
            </div>
            <div className={styles['en']}>
              {props.state.innerpage.data.video.title_en}
            </div>
            <div className={styles['ledby']}>
              {props.state.innerpage.data.video.ledby}
            </div>
            {props.state.innerpage.data.video.videoType == 'vr' && (
              <div className={styles['vr-video-info']}>
                ios用家請進入YouTube版面以觀賞VR效果
              </div>
            )}
          </div>
          <div className={styles['center-wrapper']}>
            {!videoPlaying ? (
              <div className={styles['btn-play-wrapper']}>
                <div
                  className={`btn ${styles['btn-play']}`}
                  onClick={() => handleBtnPlay()}
                >
                  <img
                    src={
                      require(`../../assets/innerpage/btn-${
                        props.state.innerpage.data.video.videoType == 'vr'
                          ? 'vr-'
                          : ''
                      }play.png`).default
                    }
                  />
                </div>
              </div>
            ) : (
              <div className={styles['btn-play-wrapper']}>
                <div
                  className={`btn ${styles['btn-play']}`}
                  onClick={() => handleBtnPause()}
                >
                  <img
                    src={
                      require(`../../assets/innerpage/btn-${
                        props.state.innerpage.data.video.videoType == 'vr'
                          ? 'vr-'
                          : ''
                      }play.png`).default
                    }
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default withRouter(
  connect((state) => {
    return {
      state: {
        innerpage: state.innerpage,
      },
    }
  })(Index)
)
