import React, { useState, useEffect, useRef, Fragment } from 'react'
import { Link, Route, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { Controller, Scene } from 'react-scrollmagic'

import './learning.css'
import Sequence from './ImageSequence/Sequence'

import styles from './index.module.scss'

function Index(props) {
  const ref = useRef()

  return (
    <div className={styles['main-wrapper']}>
      <Controller>
        <Scene duration="1000%" triggerHook="onLeave" pin>
          {(progress) => {
            props.onProgressChange(progress)
            return (
              <div style={{ height: '100vh', position: 'relative' }}>
                <Sequence
                  imageSequenceArray={props.imageSequence}
                  ref={ref}
                  progress={progress}
                />
              </div>
            )
          }}
        </Scene>
      </Controller>
    </div>
  )
}

export default Index
