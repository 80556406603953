import React, { useState, useEffect, useRef } from 'react'
import { Link, Route, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import ReactHtmlParser from 'react-html-parser'

import styles from './learning.module.scss'

import ScrollImageSequence from './scroll-image-sequence'

import ShuenwanImageSequence from './ImageSequence/Image-Array/shuenwan'
import ShuenwanMobileImageSequence from './ImageSequence/Image-Array/shuenwan-mobile'
import OldDistrictOfficeImageSequence from './ImageSequence/Image-Array/old-district-office'
import OldDistrictOfficeMobileImageSequence from './ImageSequence/Image-Array/old-district-office-mobile'
import ManMouImageSequence from './ImageSequence/Image-Array/manmou'
import ManMouMobileImageSequence from './ImageSequence/Image-Array/manmou-mobile'

function Index(props) {
  const mainWrapperRef = useRef(null)
  const [contentWrapperStickly, setContentWrapperStickly] = useState(false)
  const [imageSequenceProcess, setImageSequenceProcess] = useState(0)
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)

  const windowScrollEvent = () => {
    if (
      !contentWrapperStickly &&
      window.pageYOffset >= window.innerHeight * 2
    ) {
      setContentWrapperStickly(true)
    } else {
      setContentWrapperStickly(false)
    }
  }

  const getDesc = () => {
    return props.state.innerpage.data.learning.descs.find((config) => {
      if (
        config.startAt <= imageSequenceProcess &&
        imageSequenceProcess <= config.endAt
      ) {
        return config
      }
    }).content
  }

  const getImageSequence = (imageSequenceName) => {
    switch (imageSequenceName) {
      case 'shuenwan':
        return windowWidth < 641
          ? ShuenwanMobileImageSequence
          : ShuenwanImageSequence
        break
      case 'old-district-office':
        return windowWidth < 641
          ? OldDistrictOfficeMobileImageSequence
          : OldDistrictOfficeImageSequence
      case 'manmou':
        return windowWidth < 641
          ? ManMouMobileImageSequence
          : ManMouImageSequence
        break
    }
  }

  const scrollImageSequenceProgress = (progress) => {
    setImageSequenceProcess(progress * 100)
  }

  useEffect(() => {
    window.addEventListener('scroll', windowScrollEvent)

    return () => {
      window.removeEventListener('scroll', windowScrollEvent)
    }
  }, [])

  return (
    <div className={styles['main-wrapper']}>
      <ScrollImageSequence
        imageSequence={getImageSequence(
          props.state.innerpage.data.learning.imageSequenceName
        )}
        onProgressChange={scrollImageSequenceProgress}
      />
      <div
        className={`${styles['content-wrapper']} ${
          contentWrapperStickly ? styles['stickly'] : ''
        }`}
      >
        <div className={styles['top-overlay-color']}></div>
        <div className={styles['bottom-overlay-color']}></div>
        <div className={styles['content-inner-wrapper']}>
          <div className={styles['title-wrapper']}>
            <div className={styles['tc']}>
              {props.state.innerpage.data.learning.title_tc}
            </div>
            <div className={styles['en']}>
              {props.state.innerpage.data.learning.title_en}
            </div>
          </div>
          <div className={styles['center-wrapper']}>
            {/* <div className={styles['desc-wrapper']}>
              <div className={styles['desc-inner-wrapper']}>
                <div className={styles['desc-title-wrapper']}>
                  <div className={styles['tc']}>{getDesc().title_tc}</div>
                  <div className={styles['en']}>{getDesc().title_en}</div>
                </div>
                <p>{ReactHtmlParser(getDesc().desc_tc)}</p>
                <p>{ReactHtmlParser(getDesc().desc_en)}</p>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default withRouter(
  connect((state) => {
    return {
      state: {
        innerpage: state.innerpage,
      },
    }
  })(Index)
)
