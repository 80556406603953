import React, { useState, useEffect, useRef, Fragment } from 'react'
import { Link, Route, withRouter } from 'react-router-dom'

import patterns_config from './pattern_config.json'
import patterns_mobile_config from './pattern_mobile_config.json'
import styles from './index.module.scss'

import Element from './element'

function Index(props) {
  const [displayWidth, setDisplayWidth] = useState(window.innerWidth)
  const scrollDivRef = useRef(null)
  const [scrollPersentage, setScrollPersentage] = useState(0)
  const scrollEvent = (e) => {
    setScrollPersentage(
      (scrollDivRef.current.scrollTop /
        (scrollDivRef.current.scrollHeight -
          scrollDivRef.current.clientHeight)) *
        100
    )
  }

  const getPatterns = () => {
    let patternsConfig =
      displayWidth > 641
        ? patterns_config.patterns
        : patterns_mobile_config.patterns

    let elements = []
    for (let element of patternsConfig) {
      elements.push(
        <Element
          className={styles[element.classname]}
          startStyle={element.startStyle}
          endStyle={element.endStyle}
          current={scrollPersentage}
          key={element.classname}
        >
          <img
            src={require(`../../assets/entering/${element.imageName}`).default}
          />
        </Element>
      )
    }
    return elements
  }

  useEffect(() => {
    if (scrollPersentage > 99) {
      props.history.push('/landing')
    }
  }, [scrollPersentage])

  return (
    <div className={styles['main-wrapper']}>
      <div className={styles['background-wrapper']}></div>
      {displayWidth && displayWidth > 641 ? (
        <Fragment>
          {getPatterns()}
          <Element
            className={styles['main-title-wrapper']}
            startStyle={{
              top: {
                unit: '%',
                config: 0,
                startAt: 80,
              },
              width: {
                unit: '%',
                config: 60,
                startAt: 90,
              },
            }}
            Í
            endStyle={{
              top: {
                unit: '%',
                config: -60,
                endAt: 90,
              },
              width: {
                unit: '%',
                config: 40,
                endAt: 100,
              },
            }}
            current={scrollPersentage}
          >
            <div className={styles['bblogo-wrapper']}>
              <img src={require('../../assets/entering/bb_logo.png').default} />
            </div>
            <div className={styles['title-wrapper']}>
              <img src={require('../../assets/entering/title.png').default} />
              <Element
                className={styles['instruction-wrapper']}
                startStyle={{
                  fontSize: {
                    unit: 'px',
                    config: 50,
                    startAt: 90,
                  },
                  opacity: {
                    unit: '',
                    config: 0,
                    startAt: 75,
                  },
                }}
                endStyle={{
                  fontSize: {
                    unit: 'px',
                    config: 30,
                    endAt: 100,
                  },
                  opacity: {
                    unit: '',
                    config: 1,
                    endAt: 80,
                  },
                }}
                current={scrollPersentage}
              >
                拉動畫面發掘大埔與印蹈的美！
              </Element>
            </div>
          </Element>
        </Fragment>
      ) : (
        <Fragment>
          {getPatterns()}
          <Element
            className={styles['main-title-wrapper']}
            startStyle={{
              top: {
                unit: '%',
                config: -10,
                startAt: 80,
              },
            }}
            Í
            endStyle={{
              top: {
                unit: '%',
                config: -70,
                endAt: 100,
              },
            }}
            current={scrollPersentage}
          >
            <div className={styles['bblogo-wrapper']}>
              <img src={require('../../assets/entering/bb_logo.png').default} />
            </div>
            <div className={styles['title-wrapper']}>
              <img src={require('../../assets/entering/title.png').default} />
              <Element
                className={styles['instruction-wrapper']}
                startStyle={{
                  opacity: {
                    unit: '',
                    config: 0,
                    startAt: 75,
                  },
                }}
                endStyle={{
                  opacity: {
                    unit: '',
                    config: 1,
                    endAt: 80,
                  },
                }}
                current={scrollPersentage}
              >
                拉動畫面發掘大埔與印蹈的美！
              </Element>
            </div>
          </Element>
        </Fragment>
      )}
      <div className={styles['scroll-icon-wrapper']}>
        {displayWidth && displayWidth > 641 ? (
          <img src={require('../../assets/entering/scroll.png').default} />
        ) : (
          <img src={require('../../assets/entering/scrollup.png').default} />
        )}
      </div>
      <div className={styles['hkadc-logo-wrapper']}>
        <img src={require('../../assets/entering/hkadc_logo.png').default} />
      </div>
      <div
        className={styles['scroll-wrapper']}
        ref={scrollDivRef}
        onScroll={scrollEvent}
      >
        <div className={styles['scroll-content']}></div>
      </div>
    </div>
  )
}

export default Index
